@import "../../styles/variables";

.button {
  border-radius: 20px;
  border: none;
  outline: none;
  margin: calculateRem(16) 0 calculateRem(16) 0;
  padding: calculateRem(12) calculateRem(32);
  cursor: pointer;
  &:disabled {
    background: $background;
    padding: calculateRem(12) calculateRem(32) !important;
    cursor: default;
  }
  &_small {
    padding: calculateRem(10);
  }
}
