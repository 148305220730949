@import "../../styles/variables";

.loading_spinner_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
  z-index: 4096;
  transition-property: opacity;
  transition-duration: 0.25s;
}

.loading_spinner {
  display: block;
  margin: 0 auto;
  fill: #000;

  circle {
    animation-name: upAndDown;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.05, 0.2, 0.35, 1);
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 0.18s;
    }

    &:nth-child(3) {
      animation-delay: 0.36s;
    }
  }
}

@keyframes upAndDown {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
    transform: translateY(-10px);
  }
  75% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.address_spinner {
  height: calculateRem(18);
  align-self: center;
  width: calculateRem(220);
  padding: calculateRem(12) calculateRem(5);
}
