.form_group {
  padding: calculateRem(10) 0;
}

.input {
  display: flex;
  flex-direction: column;
  margin: calculateRem(10) 0;
}

label {
  margin-bottom: calculateRem(5);
  color: #76808f;
}

.form_input {
  background-color: transparent;
  font-family: $font;
  border: 1px solid $background;
  outline: none;
  padding: calculateRem(10);
}

.select__value-container .select__value-container--has-value {
  background: transparent !important;
}
