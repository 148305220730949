@import "../../styles/variables";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  font-family: $font;
  font-size: calculateRem(16);
  top: 0;
  img {
    width: calculateRem(40);
    height: calculateRem(40);
  }
}
