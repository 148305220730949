@import "../../styles/variables";

.star-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.star {
  cursor: pointer;
  width: 2em;
  height: 2em;
  background-color: grey;
  -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  .selected {
    background-color: red;
  }
}
