@import "../../common/styles/variables";

.modalWindow {
  background: #313541;
}

.modalFormWindow {
  background: #313541;
  width: 50%;
  small {
    color: $red;
  }
}

fieldset {
}

input,
textarea {
  background: transparent;
}

textarea {
  resize: none;
  padding: calculateRem(5);
  outline: none;
}

.cnt {
  font-family: $font;
  padding: calculateRem(30);
  display: flex;
  flex-direction: column;
  h4 {
    align-self: center;
  }
}

.form_heading {
  text-align: center;
  color: #ffffff;
}

a {
  color: #dc5a60;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: calculateRem(10);
}

.button {
  margin-right: calculateRem(5);
}

.close {
  position: absolute !important;
}

.container {
  padding: calculateRem(20);
}

.input_area {
  color: #ffffff;
}

.count {
  margin-bottom: calculateRem(20);
  font-size: calculateRem(20);
  font-weight: 500;
}

.form {
  display: grid;
  grid-template-rows: 1fr 3fr 0fr;
}

.form_body {
  small {
    color: $red;
  }
}

.form_input {
  color: $text-color;
  input,
  .react_select_container,
  .react-select__control,
  .react-select__value-container {
    background: transparent;
    border: none;
    color: $text-color;
    background-color: none !important;
  }
}

.datepicker {
  background-color: transparent;
  font-family: $font;
  border: 1px solid $background;
  color: $text-color;
  outline: none;
  padding: calculateRem(10);
}

.heading {
  color: $background;
}

.card__light {
  background: #ffffff;
}

.card__light_blue {
  background: $blue-bg;
}

.card__light_pink {
  background: $pink-bg;
}

.card__light_purple {
  background: $purple-bg;
}

.react-phone-number-input__input:-webkit-autofill {
  box-shadow: none !important;
}

.react-select-container {
  outline: none;
  background: transparent;
}

.select__menu {
  background: $background !important;
  .select__menu-list {
    background: $background !important;
    .select__option,
    .select__option--is-focused {
      color: $text-color;
      font-weight: 600;
    }
  }
}

.optionLabel {
  display: flex;
  flex-direction: column;
  color: #939ba1;
  &__heading {
    display: flex;
    justify-content: space-between;
  }
  span {
    font-size: calculateRem(14);
    font-family: $font;
    font-weight: 600;
  }

  small {
    color: #939ba1;
    text-align: right;
  }
}
